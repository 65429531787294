import request from '@/utils/request'

export function QueryDashboardStatisticsData(data) {
    return request({
        url: '/api/services/app/Statistics/QueryDashboardStatisticsData',
        method: 'post',
        data
    })
}

export function QueryDashboardDateStatisticsData(data) {
    return request({
        url: '/api/services/app/Statistics/QueryDashboardDateStatisticsData',
        method: 'post',
        data
    })
}