<template>
    <div :class="className" :title="title" :id="id" :style="{ height:height, width:width }" :option="option"></div>
</template>

<script>
import echarts from 'echarts'
import macarons from './mixins/macarons.json'
import resize from './mixins/resize'

export default {
  mixins: [resize],
  props: {
    className: {
      type: String,
      default: 'echart'
    },
    id: {
      type: String,
      default: 'echart'
    },
    title: {
      type: String,
      default: '图标'
    },
    width: {
      type: String,
      default: '200px'
    },
    height: {
      type: String,
      default: '200px'
    },
    option: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  data() {
    return {
      chart: null,
      loadingTxt: '数据加载中...',
      loadingIconColor: 'rgba(64, 158, 255, 1)',
      loadingBackground: 'rgba(255, 255, 255, 0.8)',
      noDataTxt: '暂无数据!',
      noDataIconColor: 'rgba(64, 158, 255, 0)',
      noDataBackground: 'rgba(255, 255, 255, 1)',
      optionConfig: {}
    }
  },
  mounted() {
    this.initChart()
  },
  beforeDestroy() {
    if (!this.chart) {
      return
    }
    this.chart.dispose()
    this.chart = null
  },
  methods: {
    initChart() {
      echarts.registerTheme('macarons', macarons)
      this.chart = echarts.init(document.getElementById(this.id), 'macarons')
      var option = {
        title: {
          text: this.$root.isPc ? this.title : ''
        },
        tooltip: {
          trigger: 'axis'
        },
        dataZoom: {
          show: true,
          start: 10,
          end: 80
        },
        legend: {
          data: []
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: 50,
          containLabel: true
        },
        toolbox: {
          right: '2%',
          feature: {
            dataView: {
              show: this.$root.isPc ? true :false,
              contentToOption(dom, opt) {
                console.log(opt)
              }
            },
            saveAsImage: {
              show: this.$root.isPc ? true :false,
              backgroundColor: "#ffffff",
            }
          }
        },
        xAxis: {
          type: 'category',
          boundaryGap: false, // 为柱状图时设成true，两端留空
          data: []
        },
        yAxis: {
          type: 'value'
        },
        series: []
      }
      this.optionConfig = option
      this.optionConfig.series = this.option.series
      this.chart.setOption(this.optionConfig)
      // 返回当前对象
      this.returnEchartObj()
    },
    returnEchartObj() {
      this.$emit("echartObj", this.chart)
    }
  },
  watch: {
    option: {
      handler(newVal, oldVal) {
        var isHideLoading = false
        this.chart.setOption(this.optionConfig, true)
        this.chart.showLoading({
          text: this.loadingTxt,
          color: this.loadingIconColor,
          maskColor: this.loadingBackground
        })
        for (var i in this.option.series) {
          if (this.option.series[i].data.length > 0) {
            isHideLoading = true
            break
          }
        }
        // console.log(isHideLoading)
        if (isHideLoading) {
          this.chart.hideLoading()
          this.chart.setOption(this.option)
        } else {
          this.chart.showLoading({
            text: this.noDataTxt,
            color: this.noDataIconColor,
            maskColor: this.noDataBackground
          })
        }
        // 返回当前对象
        this.returnEchartObj()
      },
      deep: true
    }
  }
}
</script>