<template>
  <div class="dashboard-box">
    <div class="header_select">
      <el-radio-group v-model="value" size="small" @change="changeValue">
          <el-radio-button label="0">今日</el-radio-button>
          <el-radio-button label="1">昨日</el-radio-button>
          <el-radio-button label="2">近7天</el-radio-button>
          <el-radio-button label="3">近30天</el-radio-button>
      </el-radio-group>
    </div>
    <!-- 第一行 -->
    <el-row class="dashboard-row" :gutter="20">
            
            <!-- 新增 -->
            <el-col :span="12">
                <el-card >
                    <div slot="header"
                         class="clearfix">
                        <span class="card-title">新增</span>
                        <!-- <router-link to="/merchant/list">
                            <el-button style="float: right; padding: 3px 0"
                                       type="text">查看商户</el-button>
                        </router-link>
                        <router-link to="/member/list">
                            <el-button style="float: right; padding: 3px 20px"
                                       type="text">查看会员</el-button>
                        </router-link>
                        <router-link to="/agent/list">
                            <el-button style="float: right; padding: 3px 0"
                                       type="text">查看代理</el-button>
                        </router-link> -->
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <router-link  :to="{path:'/merchant/list',query:{time:this.value}}">
                                <div class="statistics-cell">
                                    <div class="icon i8 icon1">
                                        <i class="el-icon-s-shop"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{statisticsData.merchants}}</div>
                                        <div class="title">新增商户</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>
                        <el-col :span="8">
                            <router-link  :to="{path:'/member/list',query:{time:this.value}}">
                                <div class="statistics-cell">
                                    <div class="icon i2 icon1">
                                        <i class="el-icon-user-solid"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{statisticsData.members}}</div>
                                        <div class="title">新增会员</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>
                        <el-col :span="8">
                             <router-link  :to="{path:'/agent/list',query:{time:this.value}}">
                                <div class="statistics-cell">
                                    <div class="icon i7 icon1">
                                        <i class="el-icon-s-check"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{statisticsData.agents}}</div>
                                        <div class="title">新增代理商</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card>
                    <div slot="header"
                         class="clearfix">
                        <span class="card-title">数据概要</span>
                        <!-- <router-link to="/device/list">
                            <el-button style="float: right; padding: 3px 0"
                                       type="text">管理设备</el-button>
                        </router-link> -->
                    </div>
                    <el-row :gutter="20">
                        <el-col :span="8">
                            <router-link  :to="{path:'/device/list'}">
                                <div class="statistics-cell">
                                    <div class="icon i6 icon1">
                                        <i class="el-icon-s-custom"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{info.devices}}</div>
                                        <div class="title">设备总数</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>
                        <el-col :span="8">
                            <router-link  :to="{path:'/device/list',query:{isOnline:1}}">
                                <div class="statistics-cell">
                                    <div class="icon i1 icon1">
                                        <i class="el-icon-s-claim"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{info.onlineDevices}}</div>
                                        <div class="title">在线设备数</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>
                        <el-col :span="8">
                            <router-link  :to="{path:'/device/list',query:{isOnline:0}}">
                                <div class="statistics-cell">
                                    <div class="icon i11 icon1">
                                        <i class="el-icon-coin"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{info.offlineDevices}}</div>
                                        <div class="title">离线设备数</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>

        <!-- 第二行 -->
        <el-row class="dashboard-row" :gutter="20">
            <!-- 平台收益 -->
            <el-col :span="12" v-if="false">
                <el-card>
                    <div slot="header"
                         class="clearfix">
                        <span class="card-title">平台收益</span>
                    </div>
                    <el-row :gutter="50">
                        <el-col :span="8">
                            <router-link  :to="{path:'/finance/earningsLogs',query:{time:this.value}}">
                                <div class="statistics-cell">
                                    <div class="icon i3 icon1">
                                        <i class="el-icon-s-marketing"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{statisticsData.platformProfit}}</div>
                                        <div class="title">平台收益</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>

                        <el-col :span="8">
                            <router-link  :to="{path:'/finance/cash/list',query:{time:this.value,status:1}}">
                                <div class="statistics-cell">
                                    <div class="icon i4 icon1">
                                        <i class="el-icon-platform-eleme"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{statisticsData.takeoutedAmount}}</div>
                                        <div class="title">提现金额</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>
                    </el-row>

                </el-card>
            </el-col>
            <!-- 订单 -->
            <el-col :span="12">
                <el-card>
                    <div slot="header"
                         class="clearfix">
                        <span class="card-title">营业数据</span>
                        <!-- <router-link to="/order/list">
                            <el-button style="float: right; padding: 3px 0"
                                       type="text">查看订单</el-button>
                        </router-link> -->
                    </div>
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <router-link  :to="{path:'/order/list',query:{isStaffOrder:1,time:this.value,status:8}}">
                                <div class="statistics-cell">
                                    <div class="icon i6 icon1">
                                        <i class="el-icon-s-custom"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{statisticsData.staffOrders}}</div>
                                        <div class="title">员工订单</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>
                        <el-col :span="8">
                             <router-link  :to="{path:'/order/list',query:{status:8,time:this.value}}">
                                <div class="statistics-cell">
                                    <div class="icon i1 icon1">
                                        <i class="el-icon-s-claim"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{statisticsData.orders}}</div>
                                        <div class="title">完成订单</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>
                        <el-col :span="8">
                            <router-link  :to="{path:'/order/list',query:{time:this.value}}">
                                <div class="statistics-cell">
                                    <div class="icon i11 icon1">
                                        <i class="el-icon-coin"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{statisticsData.orderDevices}}</div>
                                        <div class="title">订单设备</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>

                    </el-row>
                    <el-row :gutter="10">
                        <el-col :span="8">
                            <router-link  :to="{path:'/order/list',query:{paymentType:8,time:this.value,status:8}}">
                                <div class="statistics-cell">
                                    <div class="icon i9 icon1">
                                        <i class="fa fa-wechat"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{statisticsData.wechatOrderAmount}}</div>
                                        <div class="title">微信流水</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>
                        <el-col :span="8">
                            <router-link  :to="{path:'/order/list',query:{paymentType:16,time:this.value,status:8}}">
                                <div class="statistics-cell">
                                    <div class="icon i10 icon1">
                                        <i class="el-icon-shopping-cart-full"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{statisticsData.alipayOrderAmount}}</div>
                                        <div class="title">支付宝流水</div>
                                    </div>
                                </div>
                             </router-link>
                        </el-col>
                        <el-col :span="8">
                            <router-link  :to="{path:'/order/list',query:{time:this.value,status:8}}">
                                <div class="statistics-cell">
                                    <div class="icon i5 icon1">
                                        <i class="el-icon-s-data"></i>
                                    </div>
                                    <div class="info-wrap">
                                        <div class="num">{{statisticsData.orderAmount}}</div>
                                        <div class="title">订单流水</div>
                                    </div>
                                </div>
                            </router-link>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
            <el-col :span="12">
                <el-card style="padding-bottom: 10px;">
                    <div slot="header"
                         class="clearfix">
                        <span class="card-title">待处理事务</span>
                    </div>
                    <el-row :gutter="80">
                        <el-col :span="12">
                            <div class="matter-item">
                                <div class="title">未处理反馈</div>
                                <div class="num">{{info.unHandledFeedbacks}}</div>
                                <div class="btn">
                                    <router-link to="/member/questionback">
                                        <el-button style="float: right; padding: 0.1875rem 0"
                                                   type="text">去处理</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </el-col>
                        <!--<el-col :span="12">
                            <div class="matter-item">
                                <div class="title">待处理商城订单</div>
                                <div class="num">{{info.unHandledShoppingOrders}}</div>
                                <div class="btn">
                                    <router-link to="/device/mallorders?status=4">
                                        <el-button style="float: right; padding: 0.1875rem 0"
                                                   type="text">去处理</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </el-col>-->
                        <!--<el-col :span="12">
                            <div class="matter-item">
                                <div class="title">未处理提现数</div>
                                <div class="num">{{info.unHandledTakeoutApplies}}</div>
                                <div class="btn">
                                    <router-link to="/finance/cash/list?status=0">
                                        <el-button style="float: right; padding: 0.1875rem 0"
                                                   type="text">去处理</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </el-col>-->
                        <el-col :span="12">
                            <div class="matter-item">
                                <div class="title">有绑定输液宝商家未审核数量</div>
                                <div class="num">{{info.unAuditedMerchants}}</div>
                                <div class="btn">
                                    <router-link to="/merchant/list?isActive=0">
                                        <el-button style="float: right; padding: 0.1875rem 0"
                                                   type="text">去处理</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </el-col>
                        <el-col :span="12">
                            <div class="matter-item">
                                <div class="title">已结束未支付的订单数量</div>
                                <div class="num">{{info.unpaidOrders}}</div>
                                <div class="btn">
                                    <router-link  :to="{path:'/order/list',query:{status:4}}">
                                        <el-button style="float: right; padding: 0.1875rem 0"
                                                   type="text">去处理</el-button>
                                    </router-link>
                                </div>
                            </div>
                        </el-col>
                    </el-row>
                </el-card>
            </el-col>
        </el-row>
        <!-- 第三行 -->
        <el-row class="dashboard-row" :gutter="20">
            <el-col :span="12">
                <el-card>
                    <div slot="header"
                         class="clearfix">
                        <span class="card-title">交易额支付方式占比</span>
                    </div>
                    <div>
                        <echart id="chatEchart" width="100%" title="" height="203px" :option="options"></echart>
                    </div>
                </el-card>
            </el-col>
        </el-row>
  </div>
</template>

<script>
import * as funDashboard from '@/api/statistics/index'
import echart from "@/components/Echarts/Index";
export default {
  name: 'dashboard',
  components: {
    echart
  },
  data() {
    return {
      options: {},
      value: '0',
      time: '',
      info: {},
      statisticsData: {}
    }
  },
  activated() {
    this.gainData()
    this.gainDataType(this.value)
  },
  methods: {
    async gainData() {
        funDashboard.QueryDashboardStatisticsData().then(res => {
            this.info = res
            if (res.unHandledFeedbacks != 0) {
                this.confirm(`您有${ res.unHandledFeedbacks }条未处理的问题反馈`, '提示', {
                    confirmButtonText: '马上去处理',
                    cancelButtonText: '稍后',
                    type: 'warning',
                }).then(() => {
                    this.$router.push('/member/questionback')
                })
            }
        })
    },
    async gainDataType(value) {
        window.$common.fullLoading()
        funDashboard.QueryDashboardDateStatisticsData({ statisticsType: value }).then(res => {
            window.$common.closeFullLoading()
            this.statisticsData = res
            this.options = {
                tooltip: {
                    trigger: 'item'
                },
                legend: {
                    data: ['微信', '支付宝']
                },
                dataZoom: {
                    show: false
                },
                xAxis: {
                    show: false
                },
                yAxis: {
                    show: false
                },
                series: [
                    {
                        name: '交易额占比',
                        type: 'pie',
                        radius: '70%',
                        top: "10%",
                        avoidLabelOverlap: false,
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: res.wechatOrderAmount, name: '微信' },
                            { value: res.alipayOrderAmount, name: '支付宝' }
                        ]
                    }
                ]
            }
        })
    },
    changeValue() {  
        this.gainDataType(this.value)
        this.time=this.value
    }
  }
}
</script>

<style scoped lang="scss">
.dashboard {
  &-box {
    width: 100%;
    height: auto;
    min-height: 650px;
    padding:10px 0;
  }
}
/deep/ .dashboard-row {
  margin-bottom: 20px;
  .el-row {
    padding-bottom: 20px;
  }
  .el-card__header {
    padding: 12px 20px;
  }
}
.statistics-cell {
    display: flex;
    align-items: center;
    .icon {
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 1.875rem;
      // margin-right: 10px;
      &.i1 {
        background-color: #ff4a3b;
      }
      &.i2 {
        background-color: #00af51;
      }
      &.i3 {
        background-color: #ae29b0;
      }
      &.i4 {
        background-color: #00d3b1;
      }
      &.i5 {
        background-color: #2babef;
      }
      &.i6 {
        background-color: #ea6c80;
      }
      &.i7 {
        background-color: #e0a300;
      }
      &.i8 {
        background-color: #00bcc3;
      }
      &.i9 {
        background-color: #99ccff;
      }
      &.i10 {
        background-color: #66ffcc;
      }
      &.i11 {
        background-color: #3399ff;
      }
    }
    .info-wrap {
      // flex: 1;
      overflow: hidden;
    }
    .num {
      font-size: 1.5rem;
      font-weight: bold;
    }

    .title {
          color: #747474;
    }
  }
  .matter-item {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-bottom: 0.0625rem solid #f0f0f0;
    height: 3.75rem;
    cursor: pointer;
    .title {
      // flex: 1;
      width: 25rem;
    }
    .num {
      width: 6.25rem;
      font-weight: bold;
      font-size: 1rem;
      color: #436be6;
      text-align: center;
    }
    .btn {
      width: 6.25rem;
    }
  }
  .card-title {
    font-weight: bold;
  }
  .header_select {
    margin-bottom: 1.875rem;
  }
  .icon1 {
    margin-right: 1.25rem
  }
  .pingtai /deep/ .el-card__body {
    height: 152px;
  }
</style>
