export default {
  data() {
    return {
      __resizeInterval: null
    }
  },
  mounted() {
    this.resizeInterval()
    window.addEventListener('resize', () => {
      this.resizeHandler()
    })
  },
  watch: {
    "$store.getters.collapse"() {
      this.resizeHandler(800)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', () => {
      this.resizeHandler()
    })
  },
  methods: {
    resizeInterval() {
      if (this.__resizeInterval) {
        clearInterval(this.__resizeInterval)
      }
      this.__resizeInterval = setInterval(() => {
        this.resizeHandler()
        // 保证30秒后自动停止
        setTimeout(() => {
          clearInterval(this.__resizeInterval)
        }, 30 * 1000);
      }, 100)
    },
    resizeHandler(wait) {
      wait = wait || 0
      if (this.chart) {
        setTimeout(() => {
          this.chart.resize()
          clearInterval(this.__resizeInterval)
        }, wait);
      }
    }
  }
}

